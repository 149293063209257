<template lang="pug">
v-data-table.hoverable(
  :headers="headers",
  :items="controllers",
  show-expand,
  single-expand,
  @item-expanded="itemExpanded",
  light
)
  template(v-slot:expanded-item="{ headers, item }")
    td(:colspan="headers.length")
      v-row
        v-col
          v-row
            v-col
              .text-h6 Inputs
          v-row
            v-col
              v-switch(
                v-model="editingController.universal_inputs",
                label="Universal Inputs",
                color="brand"
              )
          v-row
            v-col
              v-text-field(
                v-model="editingController.ai_count",
                :label="editingController.universal_inputs ? 'Universal Inputs' : 'Analog Inputs'"
              )
            v-col(v-if="!editingController.universal_inputs")
              v-text-field(
                v-model="editingController.bi_count",
                label="Binary Inputs"
              )
        v-col
          v-row
            v-col
              .text-h6 Outputs
          v-row
            v-col
              v-switch(
                v-model="editingController.universal_outputs",
                label="Universal Outputs",
                color="brand"
              )
          v-row
            v-col
              v-text-field(
                v-model="editingController.ao_count",
                :label="editingController.universal_outputs ? 'Universal Outputs' : 'Analog Outputs'"
              )
            v-col(v-if="!editingController.universal_outputs")
              v-text-field(
                v-model="editingController.bo_count",
                label="Binary Outputs"
              )
      v-row
        v-col
          v-row
            v-col.text-h6 Special I/O
          v-row
            v-col
              v-text-field(
                v-model="editingController['120v_inputs']",
                label="120V Inputs"
              )
            v-col
              v-text-field(
                v-model="editingController['120v_outputs']",
                label="120V Outputs"
              )

      v-row
        v-col(cols=3)
          v-switch(
            v-model="editingController.mpc_submodule",
            label="MPC Submodule",
            color="brand"
          )
        v-col.text-right.pb-6
          v-btn(text, @click="editCancel") Cancel
          v-btn.white--text(color="green", @click="saveController") Save

  v-overlay(absolute, :value="busy")
    .text-h6 Please Wait...
    v-progress-linear(indeterminate)
  v-snackbar(v-model="showAlert", dark, :timeout="3000") {{ message }}
</template>

<script>
import _ from "lodash";
export default {
    data () {
        return {
            dti,
            message: "",
            busy: false,
            controllers: [],
            editingController: {},
        };
    },
    methods: {
        async saveController () {
            let results = await this.sendCommand({
                action: "updateController",
                parameters: this.editingController,
            });

            if (results) {
                this.message = `Controller updated.`;
                let updatedController = this.controllers.find(
                    (cont) => cont.id === this.editingController.id
                );
                _.merge(updatedController, this.editingController);
            } else {
                this.message = `Failed to update controller.`;
            }
        },
        itemExpanded (data) {
            this.editingController = _.cloneDeep(data.item);
        },
        editCancel () {},
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== "";
            },
            set (val) {
                if (val === false) {
                    this.message = "";
                }
            },
        },
        headers () {
            let ret = [
                {
                    text: "Product ID",
                    value: "product_id",
                    sortable: true,
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Part Number",
                    value: "part_no",
                },
            ];
            return ret;
        },
    },
    mounted () {
        this.socketEmit("getControllerData", null, (results) => {
            this.controllers = results;
        });
    },
};
</script>

<style lang="scss">
</style>
