<router>
{
    name: 'Sales Tool Controllers',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            Controllers
    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import Controllers from '@/components/salestool/controllers.vue';
export default {
    meta: {
        role: 'satcontrollers'
    },
    components: {
        Controllers
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
            tab: 0
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.satpanelsvm = this;
    }
};
</script>

<style scoped>
</style>
